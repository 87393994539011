<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.2735 4.26371C11.733 4.26371 12.0904 4.64665 12.0904 5.08066C12.0904 5.5402 11.733 5.89762 11.2735 5.89762C10.8395 5.89762 10.4565 5.5402 10.4565 5.08066C10.4565 4.64665 10.8395 4.26371 11.2735 4.26371ZM2.69541 3.85523C2.69541 2.96168 3.43578 2.22131 4.32933 2.22131H12.4989C13.418 2.22131 14.1328 2.96168 14.1328 3.85523V12.0248C14.1328 12.9439 13.418 13.6587 12.4989 13.6587H4.32933C3.43578 13.6587 2.69541 12.9439 2.69541 12.0248C2.69541 10.3398 2.69541 5.61679 2.69541 3.85523ZM3.92085 12.0248C3.92085 12.2546 4.12509 12.4333 4.32933 12.4333H12.4989C12.7287 12.4333 12.9074 12.2546 12.9074 12.0248V10.7994H3.92085V12.0248ZM3.92085 9.57393H4.66122L7.13762 5.89762C7.21421 5.76997 7.34186 5.69338 7.46951 5.69338C7.62269 5.69338 7.75034 5.76997 7.8014 5.89762L9.40978 8.29743L9.99697 7.506C10.0736 7.40388 10.1757 7.32729 10.3033 7.32729C10.4565 7.32729 10.5586 7.40388 10.6352 7.506L12.167 9.57393H12.9074V3.85523C12.9074 3.65099 12.7287 3.44675 12.4989 3.44675H4.32933C4.12509 3.44675 3.92085 3.65099 3.92085 3.85523V9.57393Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconMedia'
}
</script>
